// /config/api/user.js
import CONFIG from "@/config/config";
import request from "@/config/request";
import store from "@/store";
import {getCurrentBalance} from "@/config/api/blockchain"

/**
 * 判断是否注册/授权
 * @param {adress: address} data 
 * @returns 
 */
export function isRegister(data = {}) {
    return request.post(`${CONFIG.BASE_URL}/api/index/isRegister`, data)
}

/**
 * 获取到users表中的授权信息，并且设置state
 * @param {address: address} data 
 * @returns 
 */
export async function info(data) {
    getCurrentBalance();
    try {
        // 调用 info 接口： 后端获取不到balance的时候会异常
        //const response = await request.post(`${CONFIG.BASE_URL}/api/index/addyue`, data);
        // if (response.status === 200) {
        //     const users = response.data; //注意这个接口是以前的接口，后端返回结构不是标准的
        //     store.commit('setUsers', users);
        // }

        // 调用 info 接口 V2优化：不会出异常，但是可能获取不到的users.balance，解决方案可以考虑前端获取
        const response = await request.post(`${CONFIG.BASE_URL}/api/users/info`, data);
        if (response.status === 200) {
            if (response.data.code === 1) {
                const users = response.data.data; 
                store.commit('setUsers', users);
            } else {
                store.commit('setUsers', {});
                console.log('invalid users'); 
            }
        }

        return response; // 返回结果，便于其他地方使用
    } catch (error) {
        console.error("Error fetching user info:", error);
        throw error;
    }
}

/**
 * 授权合约后注册登录
 * @param data {
        address: address, //钱包地址
        type: type, // 链类型
        yue: 1, 
        c: invite_code, //邀请码
        balance: balance, // 钱包余额
    }  
 * @returns 
 */
export async function signin(data = {}) {
    try {
        const response = await request.post(`${CONFIG.BASE_URL}/api/index/login`, data);

        if (response.status === 200) {
            store.commit('setState', {key: 'token', value: '1'});
        }
        return response; // 返回结果，便于其他地方使用
    } catch (error) {
        console.error("Error signin:", error);
        throw error;
    }
}


/**
 * 质押申请
 * @param {*} data 
 * @returns 
 */
export function apply(data = {}) {
    return request.post(`${CONFIG.BASE_URL}/api/users/apply`, data)
}

/**
 * 撤回质押申请
 * @param {*} data 
 * @returns 
 */
export function unpledge(data = {}) {
    return request.post(`${CONFIG.BASE_URL}/api/users/unpledge`, data)
}


/**
 * 提息申请
 * @param {*} data 
 * @returns 
 */
export async function withdrawal(data = {}) {
    try {
        const response = await request.post(`${CONFIG.BASE_URL}/api/users/withdrawal`, data);

        // if (response.status === 200) {
        //     const result = response.data;
        // }
        return response; // 返回结果，便于其他地方使用
    } catch (error) {
        console.error("Error signin:", error);
        throw error;
    }
}


/**
 * @FIXME 我的投资收益情况
 * @param {*} data 
 * @returns {}
 */
export async function mining(data = {}) {
    // 模拟异步操作，使用 Promise 来包装结果
    return new Promise((resolve) => {
        let result = {
            status: 200,
            data: {
            }
        };
        resolve(result); // 可使用setTimeout后resole，模拟异步返回
    });
}